<template>
	<div>
		<div class="title">
			登录日志
		</div>
		<div style="display: flex;color: black;align-items: center;margin-left: 16px;margin-bottom: 16px;">
			<a-date-picker :disabledDate="disabledDate" @change="changeDate($event,'start')" :allowClear="true"
				v-model="startTime" placeholder="请选择起始日期" />
			<div style="width: 14px;height: 1px;background-color: rgba(0, 0, 0, 0.45);margin: 0 10px;"></div>
			<a-date-picker :disabledDate="disabledDate2" @change="changeDate($event,'end')" :allowClear="true"
				v-model="endTime" placeholder="请选择截止日期"/>
			<div style="margin-left: 12px; color:#f5222d;">
				{{  startTime && !endTime ? '（ 请选择截至日期 ）' : ''  }}
			</div>
			<div style="margin-left: 12px; color:#f5222d;">
				{{  !startTime && endTime ? '（ 请选择起始日期 ）' : ''  }}
			</div>
		</div>
		<sTable :columns="columns" :data="data" :pagination="pagination" @change="change">
			<template slot="name" slot-scope="record">
				{{record.record}}
			</template>
		</sTable>
	</div>
</template>

<script>
	import moment from 'moment'
	import sTable from '../../../components/sTable.vue'
	import {
		pageList
	} from '../../../api/anotherIndex'
	export default {
		components: {
			sTable
		},
		data() {
			return {
				startTime: null,
				endTime: null,
				columns: [{
						title: '登录人',
						dataIndex: 'name',
						key: 'name',
					},
					{
						title: '上次登录时间',
						dataIndex: 'visTime',
						key: 'visTime',
					},

					{
						title: '登录IP',
						key: 'ip',
						dataIndex: 'ip',

					},
					{
						title: '浏览器',
						dataIndex: 'browser',
						key: 'browser',
					},
					{
						title: '终端类型',
						key: 'os',
						dataIndex: 'os',

					},
				],
				data: [],
				pagination: {
					total: 0,
					current:1,
					pageSize:10,
					showTotal: total => `共 ${total} 条数据`,
					showSizeChanger: true,
					pageSizeOptions: ['5', '10', '15', '20'],
				},
			}
		},
		created() {
			//let data = '?PageNo='+this.pagination.current+'&PageSize='+this.pagination.pageSize
			this.pageList()
		},
		methods: {
			change(data) {
				this.pagination.pageSize = data.pageSize
				this.pagination.current = data.current
				/* this.startTime = this.startTime ? this.startTime : moment(this.startTime).format()
					.split('T')[0]+' 00:00:00'
				this.endTime =  this.endTime ? this.endTime : moment(this.endTime).format().split('T')[0]+' 23:59:59' */
				this.pageList()
			},
			disabledDate(current) {
				return current && current > moment(this.endTime)
			},
			disabledDate2(current) {
				return current && current < moment(this.startTime)
			},
			async pageList() {
				const res = await pageList({
					PageNo: this.pagination.current,
					PageSize: this.pagination.pageSize,
					SearchBeginTime: this.startTime ? this.startTime : undefined,
					SearchEndTime: this.endTime ? this.endTime : undefined
				})
				if (res.success) {
					this.pagination.total = res.data.totalRows
					this.data = res.data.rows
				}
			},
			changeDate(data, state) {
				if (state == 'start') {
					if (data == null) {
						this.startTime = null
					}
				} else if (state == 'end') {
					if (data == null) {
						this.endTime = null
					}
				}
				this.pagination.current = 1;
				this.startTime = !this.startTime ? this.startTime : moment(this.startTime).format().split('T')[0]+' 00:00:00'
				this.endTime =  !this.endTime ? this.endTime : moment(this.endTime).format().split('T')[0]+' 23:59:59'
				this.pageList()
			},
		}
	}
</script>

<style scoped>
	.title {
		font-size: 18px;
		color: rgba(0, 0, 0, 0.85);
		text-align: left;
		border-bottom: 1px solid #EBEBEB;
		margin: 0 16px;
		padding: 16px 0;
		margin-bottom: 16px;
		font-weight: 550
	}
</style>
